<template>
	<page-container :page="page" :locale="locale" class="index">
		<heading-image v-if="page.headingImage">
			<hero-image type="fullscreen">
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p>
						<span>{{ defaults[locale].homepage.headingImageTitle }}</span>
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent ?? ''" />
				</div>
				<div class="slider-overlay" />
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img class="object-fit" :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>

			<booking-form :booking-form="defaults[locale].bookingForm" />
		</heading-image>

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content" />

		<section v-if="defaults[locale].homepage.benefit.length" class="bookingbenefits">
			<div v-if="defaults[locale].homepage.benefitBackgroundImage" class="row benefit-image">
				<picture>
					<source :srcset="defaults[locale].homepage.benefitBackgroundImageWebp" type="image/webp" />
					<source :srcset="defaults[locale].homepage.benefitBackgroundImage" />
					<img
						class="object-fit"
						:src="defaults[locale].homepage.benefitBackgroundImage"
						:alt="defaults[locale].homepage.benefitBackgroundImageAlt"
					/>
				</picture>
			</div>
			<div class="row">
				<div v-for="benefit in defaults[locale].homepage.benefit" :key="benefit.ID" class="columns column4">
					<font-awesome-icon :icon="benefit.icon" size="1x" />
					<div v-parse-links v-html="benefit.content" />
				</div>
			</div>
			<!--<div class="row">
				<div class="columns column12 align-center">
					<book-button class="button book-now cta">
						<span>{{ defaults[locale].website.book }}</span>
					</book-button>
				</div>
			</div>-->
		</section>

		<promoblocks :items="page.promoblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<div class="header-icon" />
						<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
					</div>
				</div>
			</template>
		</promoblocks>

		<contentblocks :items="page.contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<div class="header-icon" />
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent" />
					</div>
				</div>
			</template>
		</contentblocks>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 20%);
}

.intro .column8 {
	padding: 20px 60px;
}

.bookingbenefits {
	padding-top: 0;

	.benefit-image {
		width: 98%;
		margin: 0 auto 50px;
	}

	.row {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: stretch;
		padding: 0 20px;
	}

	.book-now {
		margin: 40px auto 0;
	}

	.column4 {
		text-align: center;
		background: var(--dark-background-color);
		width: 32%;
		padding: 75px 35px;
		color: var(--secondary-background-color);

		h2,
		h3,
		h4,
		:deep(h2),
		:deep(h3),
		:deep(h4) {
			color: #fff;
			font-size: 1.4em;
		}
	}

	svg {
		margin: 0 auto 20px;
		color: var(--secondary-background-color);
		font-size: 40px;
	}
}

.header-icon {
	width: 72px;
	height: 45px;
	margin: 0 auto 25px;
	background: url('~/assets/images/logo-icon-white.png') no-repeat center center;
	background-size: 72px;
}

.blocks-header {
	.column12 {
		flex-flow: column wrap;
	}
}

.hero-content {
	position: absolute;
	max-width: 1400px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 50%;
	margin: 0 auto -160px;
	text-align: center;
	color: #fff;
	letter-spacing: 1px;

	:deep(span) {
		color: #fff;
		font-size: 1.85em;
		font-family: var(--heading-font-family);
		font-weight: 600;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.8em;
	}
}

@media (max-width: 880px) {
	.hero-content {
		margin: 0 0 -60px;

		:deep(span) {
			line-height: 140%;
		}

		:deep(p) {
			font-size: 1.2em;
			padding: 0 10px;
		}
	}

	.bookingbenefits .column4 {
		width: 100%;
		margin: 0 auto 20px;
		max-width: 500px;
	}
}
</style>
